<template>
  <b-card no-body>
    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
        :height="400"
        :data="lineChart.data"
        :options="lineChart.options"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import ChartjsComponentLineChart from '../charts-components/ChartjsComponentLineChart.vue'
// import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardBody,
    ChartjsComponentLineChart,
  },
  props: {
    lineChart: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
}
</script>
